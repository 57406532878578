// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-Body {
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-Time
{
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-size: calc(10px + 4vmin);
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;;EAEE,eAAe;EACf,YAAY;EACZ,UAAU;EACV,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".App {\r\n  text-align: center;\r\n}\r\n\r\n.App-Body {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: top;\r\n  justify-content: center;\r\n  font-size: calc(10px + 2vmin);\r\n  color: white;\r\n}\r\n\r\n.App-Time\r\n{\r\n  position: fixed;\r\n  bottom: 10px;\r\n  left: 10px;\r\n  font-size: calc(10px + 4vmin);\r\n  z-index: 100;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
